import React, { Component } from 'react'
import './Style.css';
import Carousel from 'react-gallery-carousel';
import Slider from "react-slick";
import 'react-gallery-carousel/dist/index.css';

export default class Tab3 extends Component{
    constructor(){
        super();
        this.state={
          tab3:[],
          relatedoc:[],
      banner_img:[],
      page_details:[],
      base_url:global.BaseUrl,
      
          
        }
      }
    
      componentDidMount(){

        fetch(`${this.state.base_url}/tabdetails/2`).then((resp)=>{
          resp.json().then((result)=>{
            console.log(result);
            this.setState({ page_details: result});        
          })
        });
    
        fetch(`${this.state.base_url}/tab3`).then((resp)=>{
          resp.json().then((result)=>{
            this.setState({ tab3: result});        
          })
        });
        fetch(`${this.state.base_url}/getfilesbyid/2`).then((resp)=>{
          resp.json().then((result)=>{
            this.setState({ relatedoc: result});        
          })
        });
        fetch(`${this.state.base_url}/getBannerById/2`).then((resp)=>{
          resp.json().then((result)=>{
             console.log(result);
            this.setState({ banner_img: result.banner_images});
            // this.setState({ headingType: result.data});           
          })
        });
    }
    render(){

        const {tab3,relatedoc,banner_img,page_details} = this.state;

        var settings = {
          className: "",
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          autoplay: true,
          speed: 1000,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      cssEase: 'linear',
      adaptiveHeight: true
          
        };
        // console.log(tab3);
      // const photos = [{
      //   src: "https://source.unsplash.com/jUPOXXRNdcA/1144x520",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "Viñales, Pinar del Río, Cuba",
      //   subcaption: "Photo by Simon Matzinger on Unsplash",
      //   thumbnail: "https://source.unsplash.com/jUPOXXRNdcA/100x67",
      // }, {
      //   src: "https://source.unsplash.com/SqYmTDQYMjo/1144x520",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "Viñales, Pinar del Río, Cuba",
      //   subcaption: "Photo by Simon Matzinger on Unsplash",
      //   thumbnail: "https://source.unsplash.com/SqYmTDQYMjo/100x67",
      // }, {
      //   src: "https://source.unsplash.com/XoByiBymX20/1144x520",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "Viñales, Pinar del Río, Cuba",
      //   subcaption: "Photo by Simon Matzinger on Unsplash",
      //   thumbnail: "https://source.unsplash.com/aZjw7xI3QAA/100x67",
      // }];
     
       return(
        <> 
        {/* <Carousel          
        className='framed-carousel'
       index={1}
        isLTR={true}
       isMaximized={false}
       hasSizeButton='topLeft'
       hasMediaButton='topCenter'
       hasIndexBoard='topRight'
       hasSizeButtonAtMax='bottomLeft'
       hasMediaButtonAtMax='bottomCenter'
       hasIndexBoardAtMax='bottomRight'
       hasCaptionsAtMax='top'
       hasThumbnails={false}
       hasThumbnailsAtMax={true}
       shouldMaximizeOnClick={true}
       shouldMinimizeOnClick={true}
       canAutoPlay={true}
       isAutoPlaying	={true}
      //  autoPl={50}
      autoPlayInterval	= {8000}

        activeIcon={<span className='icon-text'>x</span>}
        passiveIcon={<span className='icon-text'>o</span>}
        images={photos} /> */}
    <div className="slider_images">
        
        <Slider {...settings}>
        {
              banner_img.map(single_image=>{
                return(
                  <div>
                  <img src={`https://foodweb-macaw.ksoftpl.com/magma-backend/public/uploads/${single_image}`} className="bnr_img" />
                  </div>
                )
              })
            }
         
        </Slider>
      </div>





<section className="section-reservation-form padding-bottom-100 ">
              <div class="container">
                {
                    tab3.map(page_name=>
                        <div class="swin-sc swin-sc-title style-2">
                        <h3 class="title swin-sc-title"><span>{page_name.sub_page}</span></h3>
                      </div>
                        )
                }
                  </div>

                  <div className="container">
                    <div className="row">
                        <div className="col-md-3">

                          {
                            page_details.map(details=>{
                              // console.log(details);
                              return (
                                <img src={`https://foodweb-macaw.ksoftpl.com/magma-backend/public/uploads/${details.tab_image}`} width="200px" height="200px" alt="" />

                              )
                            })
                          }
                        </div>
                    </div>
                </div>

                {
           relatedoc.length == 0 ? null :  
            <div className="row bg-color padding-bottom-100 related_doc">
           <div className="container">
           <h3 className="res-title" style={{color:'#000',marginTop:'40px'}}>Related Document</h3>
           <div className="row mb-3">
           <div className="col-12">
           { relatedoc.map(doc=>
             
             <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
            <a href={`https://foodweb-macaw.ksoftpl.com/magma-backend/public/uploads/${doc.file_name}`} target="_blank"><p class="des realtedoc">{doc.file_title}</p></a>
             </div>
               ) }
           </div>
           </div> 
          
           </div>
           </div> 
         }
      
            </section>
    </>

) }

     
       }
