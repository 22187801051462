import React, { Component } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Slider from "react-slick";

class Work_Cafes extends Component {
  constructor() {
    super();
    this.state = {
      banner_img: [],
      base_url: global.BaseUrl,
    };
  }

  componentDidMount() {
    fetch(`${this.state.base_url}/getBannerById/11`).then((resp) => {
      resp.json().then((result) => {
        console.log(result);
        this.setState({ banner_img: result.banner_images });
        // this.setState({ headingType: result.data});
      });
    });
  }

  render() {
    const { banner_img } = this.state;

    var settings = {
      className: "",
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      cssEase: "linear",
      adaptiveHeight: true,
    };

    // const photos = [{
    //   src: "assets/images/banner/WorkCafe_banner1.jpg",
    //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
    //   caption: "",
    //   subcaption: "",
    //   thumbnail: "assets/images/banner/WorkCafe_banner1.jpg",
    // },{
    //   src: "assets/images/banner/wc_2.jpg",
    //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
    //   caption: "",
    //   subcaption: "",
    //   thumbnail: "assets/images/banner/wc_2.jpg",
    // },{
    //   src: "assets/images/banner/WorkCafe_banner3.jpg",
    //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
    //   caption: "",
    //   subcaption: "",
    //   thumbnail: "assets/images/banner/WorkCafe_banner3.jpg",
    // },{
    //   src: "assets/images/banner/wc4.png",
    //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
    //   caption: "",
    //   subcaption: "",
    //   thumbnail: "assets/images/banner/wc4.png",
    // },{
    //   src: "assets/images/banner/wc5.jpg",
    //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
    //   caption: "",
    //   subcaption: "",
    //   thumbnail: "assets/images/banner/wc5.jpg",
    // }];

    return (
      <>
        {/* <Carousel          
     className='framed-carousel'
    index={0}
     isLTR={true}
    isMaximized={false}
    hasSizeButton='topLeft'
    hasMediaButton='topCenter'
    hasIndexBoard='topRight'
    hasSizeButtonAtMax='bottomLeft'
    hasMediaButtonAtMax='bottomCenter'
    hasIndexBoardAtMax='bottomRight'
    hasCaptionsAtMax='top'
    hasThumbnails={false}
    hasThumbnailsAtMax={true}
    shouldMaximizeOnClick={true}
    shouldMinimizeOnClick={true}
    canAutoPlay={true}
    isAutoPlaying	={true}
    // autoPl={50}
    autoPlayInterval	= {8000}
     activeIcon={<span className='icon-text'>x</span>}
     passiveIcon={<span className='icon-text'>o</span>}
     images={photos} /> */}

        <div className="container-fluid slider_top">
          <div className="row">
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_one.jpg"
                alt=""
                className="bnr_img_30 padding_set"
              />
            </div>
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_two.jpg"
                alt=""
                className="bnr_img_30 padding_set mobile_hide"
              />
            </div>
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_three.jpg"
                alt=""
                className="bnr_img_30 padding_set mobile_hide"
              />
            </div>
          </div>
        </div>
        <div></div>
        <section className="section-reservation-form padding-bottom-100">
          <div class="container">
            <div class="swin-sc swin-sc-title style-2">
              <h3 class="title">
                <span>WORK CAFES</span>
              </h3>
              <p class="cafe-text text_justify">
                Work cafés have been aligned with new global standards embracing
                thematic design elements to create out of Workplace area
                concepts &amp; to enhance overall experience.
              </p>
            </div>

            <div class="reservation-form">
              <div class="row">
                <div class="col-md-8 col-md-offset-2">
                  <h4 class="service-title text-center">
                    What Do Work Cafes Offer:
                  </h4>
                  <p class="cafe-text text-center">
                    Typical floor is planned with one work café and two
                    hydration stations. The work cafe is equipped with
                    collaborative seating
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="col-md-12">
                    <ul class="list-style">
                      <li>
                        Tea/Coffee Vending - Wide variety of hot and cold
                        coffees and handpicked selection of teas
                      </li>
                      <li>
                        Hydration Station - Sleek waterspout with touch-based
                        panel dispensing 3 states of water i.e., Hot, Cold and
                        Ambient
                      </li>
                      <li>
                        Snack Spot - Retail grab-scan-pay-go setup, installed on
                        Floors 7,10, 14 & 17
                      </li>
                      <li>
                        Design includes infrastructure for employees to eat food
                        brought from home
                      </li>
                      <li>
                        Refrigerators (Vegetarian &amp; Non-Vegetarian) to store
                        home cooked food
                      </li>
                      <li>
                        Mug Concept – Leading to elimination of paper cups and
                        fortifying our sustainable practices
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="swin-sc swin-sc-contact-form light mtl"></div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Work_Cafes;
